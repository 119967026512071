a {
    text-decoration: none !important;
}

.box-header {
    padding-top: 10px;
}

.website_body {
    font-family: "Times New Roman";
    /* background: linear-gradient(135deg, aliceblue, skyblue); */
}

/*vision*/


/*partner*/
/* .description {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.partner-img {
    margin-top: 10px;
    border-radius: 50% !important;
    padding-top: 10px;
}

.partner-img:hover {
    transform: scale(1.1);
}

.partner-name {
    font-weight: bold;
    margin-top: 28px;
    margin-bottom: 8px;
    color: inherit;
}

.partner-title {
    text-transform: uppercase;
    font-weight: bold;
    color: #d0d0d0;
    letter-spacing: 2px;
    font-size: 13px;
    padding-bottom: 10px;
} */

/* .people-row {
    justify-content: space-evenly;
}

@media (min-width: 900px) {
    .people-row {
        display: flex;
    }
} */

/* .partner-panel {
    margin-bottom: 20px !important;
} */

/*notifications*/
/* .div_notifications {
    /*background: linear-gradient(135deg, rgb(196,208,255), rgba(33,169,175,0.13)) #184e8e;
} */
/* .div_notifications .carousel .carousel-control-next .carousel-control-next-icon { */
    /* color: red!important;
    background-color: red!important;
    border-color: royalblue; */
/* } */

/*services*/
.image-item {
    padding: 20px 0 20px 0px;
}

/* .custom-dot {
    background: grey;
    width: 100%;
    height: 3px;
    border-width: 0;
} */
/* .carousel-container1 {
    background-color:"red"
} */
li {
    width: 10%;
}

.card-body-center ul {
    padding: 20px;
}

/* .custom-dot--active {
    background: royalblue;
} */

/* .services_list {
    text-align: justify;
    justify-self: stretch;
    width: 100%;
} */

/* .card-body-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    align-items: last;
    background: linear-gradient(135deg,aliceblue, skyblue ) ;
    background: lightgray;
    width: 100%;
}  */



/*location*/


.div-location {
    /* color: black; */
    /*background: linear-gradient(135deg, rgb(196,208,255), rgba(33,169,175,0.13)) #184e8e;*/
    padding-bottom: 30px;
    padding-top: 30px;
    font-size: 18px;
}

/* .invalid-feedback {
    font-size: 10px !important;
}
.test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
} */